import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SaleEvent, SummaryLinkModel, TransactionWrapper } from '@core/models';
import { BaseConnectionService } from '@core/services/base-connection.service';
import { Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends BaseConnectionService {
  private http = inject(HttpClient);
  token: string;
  eventSubject: Subject<SaleEvent> = new Subject<SaleEvent>();

  // Get transaction by ID
  // Devuelve la transacion, junto la tdcTransaction ademas de la informacion del Evento
  getTransactionById(transactionId: string, brainTree = false) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?token=${this.token}&braintree=${brainTree}`;
    return this.sendRequest<TransactionWrapper>(this.http, endpoint).pipe(
      tap(data =>
        this.eventSubject.next(data.data?.transaction?.event as SaleEvent)
      )
    );
  }

  // Summary Links
  getSummaryLink(eventId: number) {
    const endpoint = `${this.apiURL}/events/${eventId}/summary-links`;
    return this.sendRequest<SummaryLinkModel>(this.http, endpoint);
  }
}
